import * as React from "react"
import {useEffect} from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  useEffect( () => {
    // Set the name of the hidden property and the change event for visibility
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    var startDate = new Date()
    var startTime = startDate.getTime()

    const handleVisibilityChange = () => {
      if (document[hidden]) {
        var endDate = new Date()
        var endTime = endDate.getTime()
        var deltaTime = endTime-startTime
        window.ga("send", "timing", "page", "hidden", deltaTime)  
      }
    }
    
    const pageClose = () => {
      var endDate = new Date()
      var endTime = endDate.getTime()
      var deltaTime = endTime-startTime
      window.ga("send", "timing", "page", "close", deltaTime) 
    }

    window.addEventListener("beforeunload",pageClose)
    window.addEventListener(visibilityChange, handleVisibilityChange)
  });

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
